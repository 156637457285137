@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(
    180deg,
    #000000 0%,
    #0a0a0a 20%,
    #0f0f0f 40%,
    #111111 60%,
    #141414 80%,
    #171717 100%
  );
  background-attachment: fixed;
  min-height: 100vh;
}

/* Adiciona um overlay gradiente sutil em cada seção */
section {
  position: relative;
}

section::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.2) 100%
  );
  pointer-events: none;
}

/* Efeito de brilho para elementos com gradiente */
.gradient-glow {
  position: relative;
}

.gradient-glow::after {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(45deg, #6C47FF, #8A6FFF);
  filter: blur(15px);
  opacity: 0.15;
  z-index: -1;
}

.video-background {
    position: relative;
    overflow: hidden; /* Isso vai impedir que o vídeo ultrapasse o contêiner */
    height: 100vh; /* Altura total da tela */
  }
  .video-background::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20%; /* Ajuste a altura do efeito de fade conforme necessário */
    background: linear-gradient(to bottom, transparent, #242635); /* Substitua #333 pela cor que você deseja */
    z-index: 2;
  }
  .video-background video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    filter: blur(0.5px) contrast(110%);
  }
  